import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageHeader } from "../components/PageHeader";
import { Container } from "../components/container";
import styled from "@emotion/styled";

const StyledHeading2 = styled.h2``;
const StyledHeading3 = styled.h3`
	margin: 60px 0 10px;
`;

const MorgenritualePage = () => (
	<Layout>
		<SEO />
		<PageHeader />
		<Container>
			<StyledHeading2>Weitere Wohltuende Morgenrituale</StyledHeading2>

			<StyledHeading3>Warmes Wasser trinken</StyledHeading3>
			<p>
				Trinke erst 2 Gläser abgekochtes, warmes Wasser, und suche
				danach etwas Ruhe am stillen Örtchen. Dem warmen Wasser kannst
				Du auch frischen Ingwer und/oder Curcuma zugegeben.
			</p>
			<ul>
				<li>Es wirkt Deiner Müdigkeit entgegen</li>
				<li>Unterstützt die Strahlkraft Deiner Haut</li>
				<li>Beugt Verdauungsproblemen vor</li>
				<li>Beruhigt Dein Gemüt</li>
				<li>
					Erfrischt dich und wird leicht von Deinem Körper aufgenommen
				</li>
			</ul>
			<StyledHeading3>Ölziehen</StyledHeading3>
			<p>
				Für das Ölziehen nehme etwa 1 EL Sesamöl in Deinen Mund und
				bewege dieses für ca. 5 min mit kauenden Bewegung in der
				Mundhöhle.&nbsp;Das Öl wird sich weißlich verfärben und den
				Geschmack verlieren. Das Öl wird danach nicht in das
				Waschbecken, sondern elegant in einem Haushaltspapier im Abfall
				entsorgt. Damit &nbsp;schonst die Umwelt und verstopfst keine
				Abwasserkanäle.
			</p>
			<ul>
				<li>Reduziert Deine Stoffwechselschlacken (ama)</li>
				<li>
					Kräftigt und weckt Deine Gesichts- und Kaumuskulatur sowie
					Dein Zahnfleisch
				</li>
				<li>Beseitigt schlechten Atem</li>
				<li>Aktiviert Deine Sinne</li>
				<li>Bringt mentale Frische</li>
				<li>Beugt Karies vor</li>
			</ul>
			<StyledHeading3>Zungeschaben</StyledHeading3>
			<p>
				Dazu verwendest Du einen kleinen Löffel oder einen speziellen
				Zungenschaber, mit dem die Zunge mehrfach vom hinteren
				Gaumenbereich bis zur Zungenspitze sanft abgeschabt wird.
			</p>
			<ul>
				<li>
					Befreit Deine Zunge von toxischen Stoffwechselabsorbtionen
				</li>
				<li>Verleiht Deinem gesamten Mundraum Frische</li>
				<li>Gegen Mundgeruch</li>
			</ul>
			<StyledHeading3>Nasenspülung</StyledHeading3>
			<p>
				Spüle dir Deine Nase mit einem Netikännchen voll lauwarmem,
				leicht gesalzenem Wasser durch. Setze das Kännchen abwechselnd
				an das linke und rechte Nasenloch und lasse das Wasser sanft
				Deine Nasengänge reinigen. Danach fettest du Deinen Naseneingang
				mit etwas Sesamöl oder Ghee nach.
			</p>
			<ul>
				<li>Spült Verunreinigungen, Pollen, Viren, Bakterien hinaus</li>
				<li>Beeinflusst Deine Sinnesorgane positiv</li>
				<li>Stärkt Deine Sehkraft</li>
				<li>
					Stärkt Deine Gedächtnisleistung und Konzentrationsfähigkeit
				</li>
				<li>Vorbeugend gegen Schnupfen, Stirnhöhlenkatarrh</li>
				<li>
					Bei regelmässiger Anwendung heilt oder lindert es
					Heuschnupfen und Pollenallergien
				</li>
			</ul>
		</Container>
	</Layout>
);

export default MorgenritualePage;
